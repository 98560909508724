import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist'


class PlanStore {
    planName = '';
    productName = '';
    productId = '';
    planId = '';
    productTenure = '';
    productTenureType = '';
    bandNo = '';
    productPrice = '';
    actualAmountWithoutPromotion = '';
    coverageDesc = '';
    currency = '';
    whatsCovered = '';
    isSubscription = false;
    selectedTenure = null;
    orderNo = '';
    invoiceNumber = '';
    selectedProduct = '';
    actualProductPrice = '';
    discountedProductPrice = '';
    paymentSystemPrice = '';
    productList = null;
    billingDate = null;
    coverageDate = null;
    payment_type_recurring = null;
    promoDefinition = null;
    fulfillmentSystemPrice = null;
    accessToken = null;
    orderStatus = '';

    updateProductInfo = (product) => {
        this.productName = product.productName;
        this.productId = product.productId;
        this.planId = product.plans && (product.plans.planId || product.plans.bandNo);
        this.deviceRrp = product.deviceRrp;
        this.coverageDesc = product.coverageDesc;
        this.productTenure = product.tenure;
        this.productTenureType = product.tenureType;
        this.productPrice = product.plans && product.plans.totalPremiumDue;
        this.actualAmountWithoutPromotion = product.plans && product.plans.totalPremiumDue * 2;
        this.currency = product.plans && product.plans.currency;
        this.productCoverage = product.productCoverage;
        this.actualProductPrice = product.plans && product.plans.actualProductPrice;
        this.discountedProductPrice = product.plans && product.plans.discountedProductPrice;
        this.paymentSystemPrice = product.plans && product.plans.paymentSystemPrice;
        this.fulfillmentSystemPrice = product.plans && product.plans.fulfillmentSystemPrice
        this.billingDate = product.billingDate;
        this.coverageDate = product.coverageDate;
        this.promoDefinition = product.promoDefinition;
        this.isSubscription = product.isSubscription;
    }

    updatePaymentType = (isRecurring) => {
        this.payment_type_recurring = isRecurring;
    }

    saveProductArrToStore = (productArr) => {
        this.productList = productArr;
    }

    updateOrderNo = (orderNo) => {
        this.orderNo = orderNo;
    }

    updateOrderStatus = (orderStatus) => {
        this.orderStatus = orderStatus;
    }

    updateInvoiceNumber = (invoiceNo) => {
        this.invoiceNumber = invoiceNo;
    }

    saveAccessToken = (accessToken) => {
        this.accessToken = accessToken;
    }
}

decorate(PlanStore, {
    planName: [persist, observable],
    productName: [persist, observable],
    productId: [persist, observable],
    planId: [persist, observable],
    productTenure: [persist, observable],
    productTenureType: [persist, observable],
    bandNo: [persist, observable],
    productPrice: [persist, observable],
    actualAmountWithoutPromotion: [persist, observable],
    coverageDesc: [persist, observable],
    currency: [persist, observable],
    isSubscription: [persist, observable],
    whatsCovered: [persist("object"), observable],
    orderNo: [persist, observable],
    selectedTenure: [persist, observable],
    productList: [persist("object"), observable],
    selectedProduct: [persist, observable],
    actualProductPrice: [persist, observable],
    discountedProductPrice: [persist, observable],
    fulfillmentSystemPrice: [persist, observable],
    payment_type_recurring: [persist, observable],
    paymentSystemPrice: [persist, observable],
    promoDefinition: [persist, observable],
    accessToken: [persist, observable],
    billingDate: [persist("object"), observable],
    coverageDate: [persist("object"), observable],
    orderStatus: [persist, observable],
    saveAccessToken: action,
    updateOrderNo: action,
    updateProductInfo: action,
    updatePaymentType: action,
    updateOrderStatus: action
})
var planStore = new PlanStore();
export default planStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('planStore', planStore);
}
