import qs from 'query-string'

const isBrowser = typeof window !== "undefined"
const queryObj = isBrowser && qs.parse(window.location.search)

const checkCountryFromQuery = (input) => process.env.NODE_ENV === "development" && queryObj?.country === input
export const getVoucherCode = queryObj?.VOUCHER
export const getCid = queryObj?.cid
export const getToken = queryObj?.scdata

// country detection flag
export const isNL = isBrowser && (checkCountryFromQuery("nl") || ["nl.uat.scplus.co", "nl.scplus.co"].includes(window.location.host))
export const isFI = isBrowser && (checkCountryFromQuery("fi") || ["fi.uat.scplus.co", "fi.scplus.co"].includes(window.location.host))

// if there is not detected is NL and FI, show BE as default
export const isBE = isBrowser && ((checkCountryFromQuery("be") || (!isNL && !isFI)) || ["be.uat.scplus.co", "be.scplus.co"].includes(window.location.host))
export const isBEDirectUrl = isBE && ['/be_nl/', '/be_fr/'].some(path => window.location.pathname.includes(path))
export const isNLDirectUrl = isNL && window.location.pathname.includes('/nl_nl/')
export const isFIDirectUrl = isFI && window.location.pathname.includes('/fi_fi/')
// language detection
const langKeyName = "previousTranslation"
export function getStorageLang() {
    return isBrowser && window.localStorage.getItem(langKeyName)
}
export function layoutChangedLang() {
    return isBrowser && (window.localStorage.getItem(langKeyName) !== null)
}
export function writeStorageLang(value) {
    isBrowser && window.localStorage.setItem(langKeyName, value)
}
export function deleteStorageLang() {
    isBrowser && window.localStorage.removeItem(langKeyName)
}
// UAT specific variables, detect by checking url on browser
export const isUAT = isBrowser && ["nl.uat.scplus.co", "fi.uat.scplus.co", "be.uat.scplus.co", process.env.NODE_ENV  === 'development' ? "localhost:8000" : ""].includes(window.location.host)

// simple detect output
isNL && console.log('Detected as NL')
isFI && console.log('Detected as FI')
isBE && console.log('Detected as BE')
isBEDirectUrl && console.log("Detected as BE direct lanuage URL")
isUAT && console.log('Currently run as UAT Environment')
!isUAT && console.log('Currently run as Prod Environment')

// common environment variables
export let env = {
    GET_PRODUCT_API_URL : "https://optimus.device.bolttech.eu:8083/productapi/products/product-by-product-id/",
    GET_PRODUCT_LIST_API_URL : "https://optimus.device.bolttech.eu:8083/productapi/products/products-by-partnerid/",
    GET_PRODUCT_LIST_X_API_KEY : "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzYW1zdW5nIiwicm9sZSI6IjEwLDMsMyIsInBvc3RhbEFkZHJlc3MiOiJyZWdpb25hbCIsImF1dGhVcGRhdGlvblJlcXVpcmVkIjpmYWxzZSwiZ2lkTnVtYmVyIjoiNTA1IiwiaWF0IjoxNTkwNzY2OTkzfQ.l6KO_wxpYQsXIedE-9XU7YfH_Z8WQrKJHblqyJjEfyj_NPBnB_T5n-gEGBZClzXF6-vK2NmaHD-DGcygIjyMwQ",
    GET_PRODUCT_BY_PRODUCT_CODE_API_URL : "https://optimus.device.bolttech.eu:8083/productapi/products/",
    GET_TRENDING_PRODUCT_BY_PARTNER_ID : "https://optimus.device.bolttech.eu:8083/productapi/products/trendingProducts/",
    GET_IMEI_OR_SERIAL_NO_BY_TOKEN : "https://optimus.device.bolttech.eu:8083/productapi/products/getImeiOrSerialNoByToken/",
    GET_CLIENT_DEVICE_BY_MODEL_KEY_API_URL : "https://optimus.device.bolttech.eu:8083/productapi/clientdevices/",
    GET_POLICY_API_URL : "https://optimus.device.bolttech.eu:8082/policy/productByImeiOrSerial",
    GET_POLICY_VOUCHER_API_URL : "https://optimus.device.bolttech.eu:8082/voucher/getByVoucherCode",
    GET_POLICY_CREATE_URL : "https://optimus.device.bolttech.eu:8082/policy/registerSamsungExternalApi/",
    GET_CUSTOMER_API_URL : "https://optimus.device.bolttech.eu:8878/customer/find",
    GET_SAMSUNG_PRODUCT_LIST_API_URL : "https://api.device.bolttech.eu/v1/products/samsung",
    GET_DEVICE_INFO_API_URL : "https://api.device.bolttech.asia/v1/device/info-code/",
    GET_DEVICE_INFO_X_API_KEY : "Rh5HYAxP6c9Rlhp9N6bOn99qP3gWoFeA9WA63VXe",
    DEVICE_VERIFICATION_API_URL : "https://api.device.bolttech.eu/v1/device/verification",
    DEVICE_VERIFICATION_X_API_KEY : "Rh5HYAxP6c9Rlhp9N6bOn99qP3gWoFeA9WA63VXe",
    ORDER_API_URL : "https://api.device.bolttech.eu/v1/order/order-with-customer",
    ORDER_X_API_KEY : "Rh5HYAxP6c9Rlhp9N6bOn99qP3gWoFeA9WA63VXe",
    PAYMENT_TOKEN_API_URL : "https://dp-pay.bolttech.eu/auth/token",
    PAYMENT_API_URL : "https://dp-pay.bolttech.eu/payment",
    SCOPE_ID_NORMAL_PRODUCT : "64c5c718-d3c9-4157-bc00-de7d490fd2a3",
    SCOPE_ID_SUBSCRIPTION_PRODUCT : "64c5c718-d3c9-4157-bc00-de7d490fd2a3",
    UPDATE_STATUS_URL : "https://eu-api.mobilecare.svc.samsung.com/mc/v3/registration/callback",
    SEND_EXCEPTION_EMAIL_URL : "https://optimus.device.bolttech.eu:8082/exceptionLogs/sendD2CExceptionEmail",

    // this payment redirect url will get override on later code
    PAYMENT_REDIRECT_URL: "handlePaymentResponse",
    PAYMENT_FRONTEND_CALLBACK: "https://api.device.bolttech.eu/v1/payment/redirect",
    PAYMENT_BACKEND_CALLBACK: "https://api.device.bolttech.eu/v1/integration/payment-notification",

    GOOGLE_ADDRESS_API_KEY : "AIzaSyADB1fpZirh2BDrK0YQ5jX7CLmz7zxRfu8",
    GTM_ID : "GTM-TCGMFPR",
    ORDER_FULL_FILL_API_URL : "https://api.device.bolttech.eu/v1/order/",
    GET_ORDER_DETAIL : "https://api.device.bolttech.asia/v1/order/list",
    IDENTITY_SERVICE_URL : "https://identity.api.device.bolttech.eu/identity/oauth/token",

    DEVICE_INSIGHT_URL : 'https://api.device.bolttech.asia/v1/device-insight/api/',
    FINGER_PRINT_SCRIPT_URL : "https://cdn.jsdelivr.net/npm/@fingerprintjs/fingerprintjs-pro@3/dist/fp.min.js",
    FINGER_PRINT_SCRIPT_TOKEN : "Cdtt9EbElNIeCzb1c3hv",
    DV_ACCESS_KEY : "com.datavisor.customertest-fVtuF",
    DV_ENVIRONMENT : "https://sg-dev.gw-dv.net",
    DV_SCRIPT_URL : "https://device-insight-web-assets.s3.ap-southeast-1.amazonaws.com/ext/raphael.min.js",
    SEON_SCRIPT_URL : "https://cdn.seon.io/js/v4/agent.js",
    SEON_ACCESS_TOKEN : "51104d2cbb158d963bd24f1b",

    // payment token
    GATSBY_PAYMENT_CLIENT_ID: "5c6ea2b88b02943de7fab31a",
    GATSBY_PAYMENT_CLIENT_SECRET: "fU0Y7x6cGUMXsj1XMsnfPgrX",
    GATSBY_PAYMENT_GRANT_TYPE: "client_credentials",
    GATSBY_PAYMENT_SCOPE: "openid accountId username roles permissions status entityUser",

    // language related
    LANGUAGES: [],
    DEFAULT_LANGUAGE: null,

    TIME_SECONDS_TAKEN_TRESHOLD: 8,
    LANDING_BANNER_DESKTOP: "https://s3.eu-central-1.amazonaws.com/static.content.bolttech.europe/images/D2C/landing_banner_desktop.png",
    LANDING_BANNER_MOBILE: "https://s3.eu-central-1.amazonaws.com/static.content.bolttech.europe/images/D2C/landing_banner_mobile.png"

}

// Netherland environment variables
if (isNL) {
    env = {
        ...env,
        GATSBY_COUNTRY: "NL",
        GATSBY_TENANT_ID: "3deab464-c0ac-49fa-8a03-fe2e19f388ec",
        GATSBY_PARTNER_CODE: "NLSAMSUOEMNA01",
        GATSBY_COUNTRY_CODE: "NLD",
        GATSBY_MOBILE_NO_CODE: "31",

        GATSBY_CLIENT_ID: "b39d0153-97f7-42ef-9a54-2061eb0df208",
        GATSBY_CLIENT_SECRET: "7832e0e9-d1f9-4150-ae24-cb95683f5de1",
        GATSBY_GRANT_TYPE: "client_credentials",

        GATSBY_PAYMENT_CURRENCY_CODE: "700",
        GATSBY_PAYMENT_SCOPE_ID_ONEOFF: "ee039794-757a-4ede-9467-cf71300b309c",
        GATSBY_PAYMENT_SCOPE_ID_MONTHLY: "022163c6-e972-47d7-828d-c6c8e36cabcf",
        GATSBY_PAYMENT_INSTANCE_ID: "bolttech_dp_eu",
        GATSBY_PAYMENT_METHOD: "worldpay-cc",
        GATSBY_PAYMENT_ACCOUNT_ONEOFF: "sceu-nl-agency-deductibleonceoff-account",
        GATSBY_PAYMENT_ACCOUNT_MONTHLY: "sceu-nl-agency-deductibleonceoff-account",
        GATSBY_SCRIPT_ID: "b75dce28-6f1b-48ee-96ae-143d9a7d6a2d",

        VWO_ACCOUNT_ID: '876922',
        
        CONTACT_US_EMAIL: 'contact.nl@careplus.co',
        CONTACT_US_PHONE: '+31858881836',
        CONTACT_US_WEBSITE: 'https://nl.scplus.co',

        // language related
        LANGUAGES: ["nl_nl"],
        DEFAULT_LANGUAGE: "nl_nl",
    }
}

// Finland environment variables
if (isFI) {
    env = {
        ...env,
        GATSBY_COUNTRY: "FI",
        GATSBY_TENANT_ID: "3744c4d7-bb88-41a8-b922-fd2b545f0886",
        GATSBY_PARTNER_CODE: "FISAMSUOEMNA01",
        GATSBY_COUNTRY_CODE: "FIN",
        GATSBY_MOBILE_NO_CODE: "358",

        GATSBY_CLIENT_ID: "e2f5819b-6f4a-4310-a768-e673480f2843",
        GATSBY_CLIENT_SECRET: "e57b2dc8-c455-4f93-8729-ae82864c26fe",
        GATSBY_GRANT_TYPE: "client_credentials",

        GATSBY_PAYMENT_CURRENCY_CODE: "700",
        GATSBY_PAYMENT_SCOPE_ID_ONEOFF: "92b06acd-dd4a-4ba1-9632-52eb0f70d1c8",
        GATSBY_PAYMENT_SCOPE_ID_MONTHLY: "29be699b-a81f-4544-b284-6ae10b38238d",
        GATSBY_PAYMENT_INSTANCE_ID: "bolttech_dp_eu",
        GATSBY_PAYMENT_METHOD: "worldpay-cc",
        GATSBY_PAYMENT_ACCOUNT_ONEOFF: "sceu-fi-agency-deductibleonceoff-account",
        GATSBY_PAYMENT_ACCOUNT_MONTHLY: "sceu-fi-agency-deductibleonceoff-account",
        GATSBY_SCRIPT_ID: "b539d2eb-685e-4435-bcee-68a2389e6ae7",

        VWO_ACCOUNT_ID: '876955',

        CONTACT_US_EMAIL: 'contact.fi@careplus.co',
        CONTACT_US_PHONE: '+358753254781',
        CONTACT_US_WEBSITE: 'https://fi.scplus.co',

        // language related
        LANGUAGES: ["fi_fi"],
        DEFAULT_LANGUAGE: "fi_fi",
    }
}

// Belgium environment variable
if (isBE) {
    env = {
        ...env,
        GATSBY_COUNTRY: "BE",
        GATSBY_TENANT_ID : "09698a6b-5a10-4443-84dd-af2ce0a47c5d",
        GATSBY_PARTNER_CODE : "BESAMSUOEMNA01",
        GATSBY_COUNTRY_CODE : "BEL",
        GATSBY_MOBILE_NO_CODE : "32",

        GATSBY_CLIENT_ID : "49a43db2-6608-42ee-be24-ea7e8d1c6a71",
        GATSBY_CLIENT_SECRET : "cbb413b4-f107-45fd-9818-d8ff0927b3e2",
        GATSBY_GRANT_TYPE : "client_credentials",

        GATSBY_PAYMENT_CURRENCY_CODE : "700",
        GATSBY_PAYMENT_SCOPE_ID_ONEOFF: "e1f18d6d-4e35-4e5a-a487-79341a18dc44",
        GATSBY_PAYMENT_SCOPE_ID_MONTHLY : "12320b9c-9699-4c05-95f9-24e39a52bdb2",
        GATSBY_PAYMENT_INSTANCE_ID : "bolttech_dp_eu",
        GATSBY_PAYMENT_METHOD : "worldpay-cc",
        GATSBY_PAYMENT_ACCOUNT_ONEOFF : "sceu-be-agency-deductibleonceoff-account",
        GATSBY_PAYMENT_ACCOUNT_MONTHLY : "sceu-be-agency-deductibleonceoff-account",
        GATSBY_SCRIPT_ID: "942c76b4-f83a-4c89-8c76-205698bf45e5",

        VWO_ACCOUNT_ID: '876949',

        CONTACT_US_EMAIL: 'contact.be@careplus.co',
        CONTACT_US_PHONE: '+3278079260',
        CONTACT_US_WEBSITE: 'https://be.scplus.co',

        // language related
        LANGUAGES: ["be_nl","be_fr"],
        DEFAULT_LANGUAGE: "be_fr",
    }
}


if (isUAT) {
    // only when is UAT
    env.LANGUAGES.push('en')
    env.DEFAULT_LANGUAGE = 'en'

    env.GET_PRODUCT_API_URL = "https://optimus.uat.device.bolttech.eu:8083/productapi/products/product-by-product-id/"
    env.GET_PRODUCT_LIST_API_URL = "https://optimus.uat.device.bolttech.eu:8083/productapi/products/products-by-partnerid/"
    env.GET_PRODUCT_BY_PRODUCT_CODE_API_URL = "https://optimus.uat.device.bolttech.eu:8083/productapi/products/"
    env.GET_TRENDING_PRODUCT_BY_PARTNER_ID = "https://optimus.uat.device.bolttech.eu:8083/productapi/products/trendingProducts/"
    env.GET_IMEI_OR_SERIAL_NO_BY_TOKEN = "https://optimus.uat.device.bolttech.eu:8083/productapi/products/getImeiOrSerialNoByToken/"
    env.GET_CLIENT_DEVICE_BY_MODEL_KEY_API_URL = "https://optimus.uat.device.bolttech.eu:8083/productapi/clientdevices/"
    env.GET_POLICY_API_URL = "https://optimus.uat.device.bolttech.eu:8082/policy/productByImeiOrSerial"
    env.GET_POLICY_VOUCHER_API_URL = "https://optimus.uat.device.bolttech.eu:8082/voucher/getByVoucherCode"
    env.GET_POLICY_CREATE_URL = "https://optimus.uat.device.bolttech.eu:8082/policy/registerSamsungExternalApi/"
    env.GET_CUSTOMER_API_URL = "https://optimus.uat.device.bolttech.eu:8878/customer/find"
    env.GET_SAMSUNG_PRODUCT_LIST_API_URL = "https://api.uat.device.bolttech.eu/v1/products/samsung"
    env.DEVICE_VERIFICATION_API_URL = "https://api.uat.device.bolttech.eu/v1/device/verification"
    env.ORDER_API_URL = "https://api.uat.device.bolttech.eu/v1/order/order-with-customer"
    env.PAYMENT_TOKEN_API_URL = "https://paydp.stag.bolttechpay.net/auth/token"
    env.PAYMENT_API_URL = "https://paydp.stag.bolttechpay.net/payment"
    env.PAYMENT_FRONTEND_CALLBACK = "https://api.uat.device.bolttech.eu/v1/payment/redirect"
    env.PAYMENT_BACKEND_CALLBACK = "https://api.uat.device.bolttech.eu/v1/integration/payment-notification"
    env.SEND_EXCEPTION_EMAIL_URL = "https://optimus.uat.device.bolttech.eu:8082/exceptionLogs/sendD2CExceptionEmail"

    env.IDENTITY_SERVICE_URL = "https://identity.api.uat.device.bolttech.eu/identity/oauth/token"

    env.GATSBY_PAYMENT_CLIENT_SECRET = "45FGL03294pL3q76WMK6v7B64PFvt42l"
    env.UPDATE_STATUS_URL = "https://stg-eu-api.mobilecare.svc.samsung.com/mc/v3/registration/callback"
    
    env.LANDING_BANNER_DESKTOP = "https://s3.eu-central-1.amazonaws.com/static.uat.content.bolttech.europe/images/D2C/landing_banner_desktop.png"
    env.LANDING_BANNER_MOBILE = "https://s3.eu-central-1.amazonaws.com/static.uat.content.bolttech.europe/images/D2C/landing_banner_mobile.png"

    if(isNL){
        env.GATSBY_CLIENT_ID = "b6f39193-ed68-4880-bdcc-e667b1223b68"
        env.GATSBY_CLIENT_SECRET = "94dd085f-623a-4929-aa5e-59f912805b8b"

        env.GATSBY_PAYMENT_CURRENCY_CODE = "700"
        env.GATSBY_PAYMENT_SCOPE_ID_ONEOFF = "ee039794-757a-4ede-9467-cf71300b309c"
        env.GATSBY_PAYMENT_SCOPE_ID_MONTHLY = "022163c6-e972-47d7-828d-c6c8e36cabcf"
        env.GATSBY_PAYMENT_INSTANCE_ID = "bolttech_dp_eu"
        env.GATSBY_PAYMENT_ACCOUNT_ONEOFF = "sceu-nl-agency-deductibleonceoff-account"
        env.GATSBY_PAYMENT_ACCOUNT_MONTHLY = "sceu-nl-agency-deductibleonceoff-account"
        env.GATSBY_SCRIPT_ID = "95cc0bac-5953-4f51-b000-271c02e967ac"
    }
    if(isFI){
        env.GATSBY_CLIENT_ID = "bfee8075-92cb-4f5c-8f0a-62f9217a1f4f"
        env.GATSBY_CLIENT_SECRET = "2f7f233e-9f5a-4c13-a104-59401bbd5ff6"

        env.GATSBY_PAYMENT_CURRENCY_CODE = "700"
        env.GATSBY_PAYMENT_SCOPE_ID_ONEOFF = "92b06acd-dd4a-4ba1-9632-52eb0f70d1c8"
        env.GATSBY_PAYMENT_SCOPE_ID_MONTHLY = "29be699b-a81f-4544-b284-6ae10b38238d"
        env.GATSBY_PAYMENT_INSTANCE_ID = "bolttech_dp_eu"
        env.GATSBY_PAYMENT_ACCOUNT_ONEOFF = "sceu-fi-agency-deductibleonceoff-account"
        env.GATSBY_PAYMENT_ACCOUNT_MONTHLY = "sceu-fi-agency-deductibleonceoff-account"
        env.GATSBY_SCRIPT_ID = "1f373810-b119-4466-8f1b-1dff7cf24503"
    }
    if(isBE){
        env.GATSBY_CLIENT_ID = "ab0a4295-0ce8-4e9e-a252-3ca7684a8cff"
        env.GATSBY_CLIENT_SECRET = "67584a14-347e-41d9-9807-8de807d49065"

        env.GATSBY_PAYMENT_CURRENCY_CODE = "700"
        env.GATSBY_PAYMENT_SCOPE_ID_ONEOFF = "e1f18d6d-4e35-4e5a-a487-79341a18dc44"
        env.GATSBY_PAYMENT_SCOPE_ID_MONTHLY = "12320b9c-9699-4c05-95f9-24e39a52bdb2"
        env.GATSBY_PAYMENT_INSTANCE_ID = "bolttech_dp_eu"
        env.GATSBY_PAYMENT_ACCOUNT_ONEOFF = "sceu-be-agency-deductibleonceoff-account"
        env.GATSBY_PAYMENT_ACCOUNT_MONTHLY = "sceu-be-agency-deductibleonceoff-account"
        env.GATSBY_SCRIPT_ID = "c4b45408-0f62-4cef-a71a-4150741e4081"
    }
}

process.env.NODE_ENV  === 'development' && console.log('env => ', env)

export const ROUTES = {
    HOME: '/',
    FAQ: '/faq',
    DEVICE_INFO: '/device-info',
    DEVICE_ELIGIBILITY: '/device-eligibility',
    PRODUCT: '/select-product',
    PERSONAL_INFO: '/personal-info',
    PAYMENT: '/payment',
    PAYMENT_SUCCESS: '/payment-success',
    PAYMENT_FAIL: '/payment-failed',
    PRIVACY: '/privacy-policy',
    TNC1YEAR: '/tnc/12mth',
    TNCMONTHLY: '/tnc/monthly',
}

export const HEADER_REQUEST = {
    "boltLanguageCode": "EN",
    "boltChannel": "web",
    "contentType": "application/json"
}

export const partnerCode = env.GATSBY_PARTNER_CODE;

export const BUTTON = {
    GET_50PERCENT_OFF: "GET 50% OFF TODAY",
    LETS_GET_STARTED: "Let’s Get Started",
    GET_THIS_PLAN_NOW: "GET THIS PLAN NOW",
    CHOOSE_MY_PLAN: "CHOOSE MY PLAN"
}

export const STATUS_CODE = {
    SUCCESS: "80001",
    INVALID_REQUEST_PARAMETERS: "80012",
    NOT_EXISTS_SAMSUNG_DEVICE_IMEI: "84001",
    ALREADY_REGISTERED_DEVICE: "84008",
    UNSUPPORTED_EULA_ACKNOWLEGDE_DATE: "84005",
    UNSUPPORTED_DEVICE_MODEL: "83006"
}

export const DISCOUNT = {
    IS_DISCOUNT: true
}

export const PRODUCT_IDS = {
    PRODUCT_MONTHLY: "SES_PH_D2C_1M",
    PRODUCT_YEARLY: "SES_PH_D2C_1Y"
}

export const DEVICE_TYPE = {
    MOBILE: "MOBILE",
    TABLET: "TABLET",
    WEARABLE: "WEARABLE"
}

export const DISCOUNT_TYPE = {
    DISCOUNT_PERCENTAGE: "Discount %",
    MONTH_PREMIUM_FOC: "month premium FOC"
}

export const BASIS_CODE_INITIAL = 'SM-'

export function isMobile() { return (function(a) {
    if (
        //eslint-disable-next-line
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
        ) ||
        //eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
        )
    )
        return true
    })(navigator.userAgent || navigator.vendor || window.opera)
}

export function isTablet() { return (function(a) {
   if(
        /ipad|android.+\d safari|tablet/i.test(a)
   )
        return true
   })(navigator.userAgent||navigator.vendor||window.opera)
}

export const TRANSLATE = {
    en: "English",
    be_fr: "French",
    be_nl: "Flemish",
    fi_fi: "Finnish",
    nl_nl: "Dutch"
}

export const TRANSLATE_SHORT = {
    en: "EN",
    be_fr: "FR",
    be_nl: "NL",
    fi_fi: "FI",
    nl_nl: "NL"
}

export const SESSION_TIMEOUT = {
    TIMEOUT: 3600000 // 60 minutes
}

export const PAYMENT_LANG_MAPPING = {
    be_fr: "fr-be",
    be_nl: "nl-be",
    nl_nl: "nl",
    fi_fi: "fi",
    en: "en",
  }